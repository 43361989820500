import React from 'react';
import styled from 'styled-components';

import W from '../styled/Wrapper';

const Bottom = styled(W)`
  padding-top: 50px;

  @media only screen and (max-width: 810px) {
    padding-top: 40px;
  }
`;

export default Bottom;
