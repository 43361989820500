import React from 'react';
import styled from 'styled-components';
import W from '../styled/Wrapper';

import SmallRichText from '../shared/SmallRichText';

const Wrapper = styled(W)``;

const Credits = ({ data }) => {
  const { credits } = data;
  return (
    <Wrapper>
        {credits && (
        <SmallRichText content={credits.raw}/>
      )}
    </Wrapper>
  );
};

export default Credits;
