import React from 'react';
import styled from 'styled-components';

import { RichText } from '@graphcms/rich-text-react-renderer';

const Wrapper = styled.span`
  font-size: 16px;
  line-height: 1;
  white-space: pre-wrap;

  @media only screen and (max-width: 810px) {
    font-size: 11px;
  }

  a {
    text-decoration: underline;
  }

  p,
  ul,
  ol {
    margin-bottom: 15px;
  }

  strong {
    //TODO: change font to bolder
    font-weight: bold;
  }
`;

const SmallRichText = ({ content, firstParMargin }) => {
  return (
    <Wrapper firstParMargin={firstParMargin}>
      <RichText content={content} />
    </Wrapper>
  );
};

export default SmallRichText;
