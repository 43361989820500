import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import RichText from '../shared/RichText';

import W from '../styled/Wrapper';
import H from '../styled/Heading';
import B from '../styled/Button';

import CloseSvg from '../../assets/x2.svg';
import NextSvg from '../../assets/arrows.svg';

const Wrapper = styled(W)`
  display: flex;

  @media only screen and (max-width: 810px) {
    display: block;
    padding-top: 10px;
  }
`;

const Heading = styled(H)`
  line-height: 1;
  margin-bottom: 20px;
`;

const TextWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colorMain};
  flex-grow: 1;

  @media only screen and (max-width: 810px) {
    border: 0;
  }
`;

const Categories = styled.div`
  margin-bottom: 30px;

  @media only screen and (max-width: 810px) {
    margin-bottom: 14px;
  }
`;

const Button = styled(({ small, ...rest }) => <B {...rest} />)`
  /* margin-right: 14px;
  margin-bottom: 14px;

  @media only screen and (max-width: 810px) {
    font-size: 16px;
    line-height: 19px;
    margin-right: 7px;
    margin-bottom: 7px;
    padding: 2px 7px;
  } */

  margin-right: 14px;
  margin-bottom: 7px;

  @media only screen and (max-width: 810px) {
    font-size: 16px;
    line-height: 19px;
    margin-right: 7px;
  }
`;

const Paragraph = styled.div`
  max-width: 907px;
  padding-bottom: 25px;

  @media only screen and (max-width: 810px) {
    padding: 0;
    max-width: 100%;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  padding-bottom: 25px;
  border-bottom: 2px solid ${({ theme }) => theme.colorMain};

  @media only screen and (max-width: 810px) {
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 10px;
    border-width: 1px;
  }
`;

const Close = styled(CloseSvg)`
  margin-right: 20px;

  @media only screen and (max-width: 810px) {
    height: 20px;
    width: 20px;
    margin-right: 7px;
  }

  path,
  rect {
    fill: ${({ theme }) => theme.colorMain} !important;
  }
`;

const Next = styled(NextSvg)`
  @media only screen and (max-width: 810px) {
    height: 20px;
    width: 20px;
  }

  path,
  rect {
    fill: ${({ theme }) => theme.colorMain} !important;
  }
`;

const Top = ({ data, isEn, nextUrl }) => {
  const { title, intro, projectCategories } = data;

  return (
    <Wrapper>
      <TextWrapper>
        <Heading size={1} as={'h1'}>
          {title}
        </Heading>
        <Categories>
          {projectCategories.map(item => {
            return (
              <Button to={isEn ? `/en/works?cat=${item.slug}` : `/works?cat=${item.slug}`} small key={item.title}>
                {item.title}
              </Button>
            );
          })}
        </Categories>
        {intro && (
          <Paragraph>
            <RichText
              content={intro.raw}
              firstParMargin
            />
          </Paragraph>
        )}
      </TextWrapper>
      <LinksWrapper>
        <Link to={isEn ? '/en/works' : '/works'}>
          <Close />
        </Link>
        <Link to={nextUrl}>
          <Next />
        </Link>
      </LinksWrapper>
    </Wrapper>
  );
};

export default Top;
