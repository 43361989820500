import React, { useEffect, useState } from 'react';
import ContextConsumer from '../helpers/context';
import SEO from '../components/Seo';
import { useStaticQuery, graphql } from 'gatsby';

import Top from '../components/project/Top';
import Sections from '../components/project/Sections';
import Credits from '../components/project/Credits';
import Bottom from '../components/project/Bottom';

import Footer from '../components/layout/Footer';

const Project = ({ pageContext, contextSet, contextData }) => {
  const allProjects = useStaticQuery(graphql`
    query allProjects {
      med {
        pageProjects(where: { id: "ckjttb1xs2rfp0b53cobpiy6j" }) {
          localizations(includeCurrent: true, locales: [pl_PL, en]) {
            projects {
              localizations(includeCurrent: true, locales: [pl_PL, en]) {
                slug
              }
            }
          }
          projects {
            slug
          }
        }
      }
    }
  `);

  const [nextUrl, setNextUrl] = useState(false);

  const { data, otherLangLink } = pageContext;

  const { seoTitle, seoDescription, seoImage, sections, localizations } = data;

  useEffect(() => {
    const isEn = !otherLangLink.startsWith('/en');
    contextSet({
      langTo: otherLangLink,
      isEn: isEn,
    });

    if (isEn) {
      const ind = allProjects.med.pageProjects.localizations[0].projects.findIndex(
        item => {
          return item.localizations[0].slug === data.slug;
        }
      );

      const nextItem =
        allProjects.med.pageProjects.localizations[0].projects[ind + 1] ||
        allProjects.med.pageProjects.localizations[0].projects[0];

      setNextUrl(`/en/works/${nextItem.localizations[0].slug}`);
    } else {
      const ind = allProjects.med.pageProjects.projects.findIndex(
        item => item.slug === data.slug
      );

      const nextItem =
        allProjects.med.pageProjects.projects[ind + 1] ||
        allProjects.med.pageProjects.projects[0];

      setNextUrl(`/works/${nextItem.slug}`);
    }
  }, []);

  return (
    <div>
      <SEO title={seoTitle} description={seoDescription} image={seoImage} />
      <Top data={data} isEn={contextData.isEn} nextUrl={nextUrl} />
      <Sections data={sections} locs={localizations} />
      <Credits data={data} locs={localizations} />
      <Bottom>
        <Footer />
      </Bottom>
    </div>
  );
};

const ConnectedProject = ({ pageContext }) => {
  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Project
          pageContext={pageContext}
          contextSet={set}
          contextData={data}
        />
      )}
    </ContextConsumer>
  );
};

export default ConnectedProject;
